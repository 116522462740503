import React, {useState, useEffect, useCallback} from "react";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../../../../graphUser";
import { loginRequest } from "../../../../authConfig";
import { useDispatch, useSelector } from "react-redux";
import { Settings } from "@material-ui/icons";
import { MenuItem } from "@material-ui/core";
import { createTheme, ThemeProvider  } from '@mui/material/styles';
import {IconButton, InputLabel, Select, Input, Box, FormControl, Button } from '@mui/material';
import { recordFrontEndError, useFetch } from "../../../../utlis/api";
import * as api from '../../../../utlis/api'
import { SET_STATS, SET_FINANCE_REQUESTS, SET_PERIOD, SET_REGION_CODE, SET_LOCATION_CODE, SET_FINANCE_REQUESTS_TOTAL_PAGES, SET_START_DATE, SET_END_DATE, SET_SEARCH_STATE } from "../../../../redux/finance_dashboard/financeDashboardTypes";
import { setStats, setFinanceRequests, setPeriod, setRegionCode, setLocationCode, setFinanceRequestsTotalPages, setStartDate, setEndDate, setSearchState } from "../../../../redux/finance_dashboard/financeDashboardAction";
import moment from "moment";
import { setShowDashboardSpinner } from "../../../../redux/spinners/spinners";
import { getMyDefaultRegionCode } from "../../../../utlis/api";
import { updateDefaultRegionCode } from "../../../../redux/me";


const theme = createTheme({
  palette: {
    newColor: "#000000",
    myWhite: "#fff"
  },
});

//refactor useEffects for try /catch error recording
const FinanceBookedHeader = ( {setInitFinanceRequests, trainingOverride, setTrainingOverride} ) => {

  const dispatch  = useDispatch();
  const axios     = useFetch()

  const financeDashboardPeriod = useSelector(state => state?.financeDashboardState.period);
  const startDate = useSelector(state => state?.financeDashboardState.startDate);
  const endDate = useSelector(state => state?.financeDashboardState.endDate);
  const search = useSelector(state => state?.financeDashboardState.filter);
  const filterSegment = useSelector(state => state?.financeDashboardState.filterSegment);
  // const searchState = useSelector(state => state?.financeDashboardState.searchState);

  const role = useSelector((state) => state?.role.role);
  const me = useSelector((state) => state?.me);
  const allLocations = JSON.parse(localStorage.getItem("alllocations"));

  const [ regions, setRegions ]                           = useState([]);
  const [ regionValue, setRegionValue ]                   = useState(null);
  const [ regionOptions, setRegionOptions ]               = useState([]);
  const [ locations, setLocations ]                       = useState([]);
  const [ locationValue, setLocationValue ]               = useState(null);
  const [ locationOptions, setLocationOptions ]           = useState([]);
  const [ tempLocationOptions, setTempLocationOptions ]   = useState([]);

  function determineColor( buttonPeriod ){
    if ( buttonPeriod === financeDashboardPeriod ){
      return 'white';
    }
    return 'black';
  }

  function formatLocationAccessString(codes){
    let string = codes.join(", ");
    return me?.defaultTecLocationCode+", "+string;
  }

  const getRegionLocations = useCallback(async (regionCode) => {
    if(regionCode === 'ALL'){
      api.getMyLocations().then( locations => {
        setLocations(locations);
        setLocationOptions([]);
      })
      // setLocations(res?.data?.data);
    }else{
      try {
        let res = await axios.get(`/tec-region/${regionCode}/location`)
        setLocations(res?.data.data);
        setLocationOptions([]);
      } catch (err) {
        recordFrontEndError('FinanceBookedHeader.jsx', 'Error getting specific region: ' + err)
      }
    }
  },[axios]);

  const [fetchRegions, setFetchRegions] = useState(false);


  useEffect(() => {

    if(regions?.length === 0 && me?.defaultTecRegionCode && role && fetchRegions === false){
      setFetchRegions(true);
      axios
      .get(`/tec-region`)
      .then((res) => {
        setRegions(res?.data.data);
        if(role === 'master'){
          getRegionLocations('ALL');
        }else{
          getRegionLocations(me.defaultTecRegionCode);
        }
        setFetchRegions(false);
      });
    }


  }, [axios, fetchRegions, getRegionLocations, me, regions, role]);

  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    if(graphData == null){
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((response) => {
        callMsGraph(response.accessToken).then(response => setGraphData(response));
      });
    }

  }, [ accounts, axios, graphData, instance, me ]);

  const updateDashboard = useCallback(async (newPeriod, regionCode = regionValue, locationCode = locationValue, overrideRole = null) => {
    dispatch(setSearchState(SET_SEARCH_STATE, 'loading'));

    setPeriod(newPeriod);

    let connstring = '/order/finance-dashboard-kpis'

    if (newPeriod === 'custom') {
      connstring += `?start_date=${startDate}&end_date=${endDate}`
    }

    if (newPeriod !== 'custom'){ 
      connstring +=`?time_period=${newPeriod}`
    }

    if ( regionCode && regionCode !== "" ) { 
      connstring += `&region_code=${regionCode}`;
    }

    if ( locationCode && locationCode !== "" ) {
      connstring += `&location_code=${locationCode}`;
    }

    if(overrideRole){
      connstring += `&sim=${role}`;
    }

    if(filterSegment && filterSegment !== "none"){
      connstring += `&filterSegment=`+filterSegment.trim();
    }

    if(search && search !== ""){
      connstring += `&filter=`+search.trim();
    }

   try{
    dispatch(setShowDashboardSpinner(true))
    let res = await axios.get(connstring)
      dispatch(setStats(SET_STATS , res?.data));
      dispatch(setShowDashboardSpinner(false))

      if(res?.data.startDate){
        const newStartDate = moment.utc(res?.data.startDate).format("YYYY-MM-DD") 
        dispatch(setStartDate( SET_START_DATE, newStartDate));

      }
      if(res?.data.endDate){
        const newEndDate = moment.utc(res?.data.endDate).format("YYYY-MM-DD") 
        dispatch(setEndDate( SET_END_DATE, newEndDate));

      }
    }catch(err) {
    };

   try{
    let res = await api.updateFinanceDashboardFinanceRequests(newPeriod, regionCode, locationCode, overrideRole, role, 1, search, startDate, endDate, filterSegment)
      dispatch(setFinanceRequests(SET_FINANCE_REQUESTS , res?.data.data));
      dispatch(setFinanceRequestsTotalPages(SET_FINANCE_REQUESTS_TOTAL_PAGES , res?.data.totalPages))
      dispatch(setShowDashboardSpinner(false))
      dispatch(setSearchState(SET_SEARCH_STATE, 'ready'));

      setInitFinanceRequests(true);
    }catch(err) {

    };

  
   },[regionValue, locationValue, filterSegment, search, startDate, endDate, role, dispatch, axios, setInitFinanceRequests]);

   const [updatingMyDefaultRegionCode, setUpdatingMyDefaultRegionCode] = useState(false);

  const findUserDefaultRegionCode = useCallback(async () => {
    let newDefaultRegionCode = await getMyDefaultRegionCode();
    if(newDefaultRegionCode?.data){
      dispatch(updateDefaultRegionCode(newDefaultRegionCode.data));
      setUpdatingMyDefaultRegionCode(false);
    }
  },[dispatch])
   
  const [dashboardRole, setDashboardRole] = useState(undefined);
  const [initalizedDashboard, setInitalizedDashboard] = useState(false); // ?

  useEffect(() => {

    if(me && !me.defaultTecRegionCode && updatingMyDefaultRegionCode === false){
      setUpdatingMyDefaultRegionCode(true);
      findUserDefaultRegionCode();
    }

    if(me && role !== 'default' && role !== 'master' && me.defaultTecRegionCode && regionValue === null && dashboardRole !== 'not-master' && initalizedDashboard === false){
      setInitalizedDashboard(true);
      setDashboardRole('not-master');
      setTrainingOverride(true);
      setRegionValue(me?.defaultTecRegionCode);
      getRegionLocations(me?.defaultTecRegionCode);
      dispatch(setRegionCode( SET_REGION_CODE, me?.defaultTecRegionCode));
      dispatch(setLocationCode( SET_LOCATION_CODE, 'ALL'))
      updateDashboard(financeDashboardPeriod, me?.defaultTecRegionCode, 'ALL', true);
      setInitFinanceRequests(true);
    }else if(role === 'master' && !trainingOverride && regionValue === null && dashboardRole !== 'master' && initalizedDashboard === false){
      setInitalizedDashboard(true);
      setDashboardRole('master');
      setRegionValue('ALL');
      dispatch(setRegionCode( SET_REGION_CODE, 'ALL'))
      getRegionLocations('ALL');
      dispatch(setLocationCode( SET_LOCATION_CODE, 'ALL'))
      updateDashboard(financeDashboardPeriod, 'ALL', 'ALL');
      setInitFinanceRequests(true);
    }

}, [dashboardRole, dispatch, financeDashboardPeriod, findUserDefaultRegionCode, getRegionLocations, initalizedDashboard, me, regionValue, role, setInitFinanceRequests, setTrainingOverride, trainingOverride, updateDashboard, updatingMyDefaultRegionCode]);

  useEffect(() => {

    async function loadAllLocations(){
        api.getMyLocations().then( locations => {
          if(locations?.length > 0){
            localStorage.setItem("alllocations", JSON.stringify(locations));
          }
        })
    }


    if (allLocations === null || allLocations.length === 0) {
      loadAllLocations();
    }

  }, [ allLocations, dispatch ])

  const [loadedAdditionalCodes, setLoadedAdditionalCodes] = useState(false);


  //Effect to set the location names...
  useEffect(() => {

    if ( locations && locations.length > 0 && locationOptions && locationOptions.length === 0) {
      
      const newLocationOptions =
      locations
        .sort(function(a, b) {
          if(a.description.toLowerCase() < b.description.toLowerCase()) return -1;
          if(a.description.toLowerCase() > b.description.toLowerCase()) return 1;
          return 0;
         })
        .map(el => ({
          code: el.locationCode,
          description: el.description
        }))

      const allOptions = [...[{code: 'ALL', description: "All Locations"}], ...newLocationOptions];

      setLocationOptions(allOptions);
      setLocationValue('ALL');

    }


    if(role !== 'master' && me?.additionalLocationCodes?.length > 0 && locationOptions?.length > 0 && tempLocationOptions?.length === 0 && loadedAdditionalCodes === false){
      setLoadedAdditionalCodes(true);
      let tempOptions = [];

      let additionalLocationCodes = me?.additionalLocationCodes;
      additionalLocationCodes.forEach(addCode => {
          var foundLocation = allLocations.find(loc => loc.locationCode === addCode);
          if(foundLocation){
            let foundInMainOptions = locationOptions.find(newLoc => newLoc.code === foundLocation.locationCode);
            let foundInTempOptions = tempOptions.find(newLoc => newLoc.code === foundLocation.locationCode);
            if(!foundInMainOptions && !foundInTempOptions){
              tempOptions.push({
                code: foundLocation.locationCode,
                description: foundLocation.description
              });
            }
          }
      });

      setTempLocationOptions(tempOptions)
    }


  }, [locations, axios, locationValue, updateDashboard, locationOptions, allLocations, me, tempLocationOptions, role, loadedAdditionalCodes])

  useEffect(() => {

    if ( regions && regions.length > 0 && regionOptions && regionOptions.length === 0 ) {
      const newRegionOptions =
        regions
        .sort(function(a, b) {
          if(a.description.toLowerCase() < b.description.toLowerCase()) return -1;
          if(a.description.toLowerCase() > b.description.toLowerCase()) return 1;
          return 0;
         })
         .map(el => ({
          code: el.regionCode,
          description: el.description
        }))

      const allOptions = [...[{code: 'ALL', description: "All Regions"}], ...newRegionOptions];

      setRegionOptions(allOptions)
    }

  }, [ regions, axios, regionValue, updateDashboard, regionOptions ])

  //When user sets custom date
  // useEffect(()=>{
    
    // if(financeDashboardPeriod !== 'custom'){
    //   return
    // }else{      
    //   updateDashboard(financeDashboardPeriod)
    // }

  // },[financeDashboardPeriod, searchState, updateDashboard])


  const onRegionChange = e => {
    setRegionValue(e.target.value);
    dispatch(setRegionCode( SET_REGION_CODE, e.target.value))
    getRegionLocations(e.target.value);
    let tempLocationValue;
    if(role === 'master' || role === 'finance-manager'){
      tempLocationValue = "ALL"
    }else{
      tempLocationValue = me?.defaultTecLocationCode
    }
    setLocations([]);
    setLocationOptions([]);
    setLocationValue(tempLocationValue);
    dispatch(setLocationCode( SET_LOCATION_CODE, tempLocationValue))

    updateDashboard(financeDashboardPeriod, e.target.value, tempLocationValue)
  }
  
  const onLocationChange = e => {
    setLocationValue(e.target.value);
    dispatch(setLocationCode( SET_LOCATION_CODE, e.target.value))

    updateDashboard(financeDashboardPeriod, regionValue, e.target.value, (role !== me?.role ? true : false))
  }

  const setCustomDateRange = (date, marker) =>{
    dispatch(setPeriod( SET_PERIOD, 'custom'))
    if(marker === 'start') {
      dispatch(setStartDate( SET_START_DATE, date));
    }
    if(marker === 'end') {
      dispatch(setEndDate( SET_END_DATE, date));
    }
    // setInitFinanceRequests(true);
  }

  return (
    <div>
        <div className="d-flex justify-content-between px-2 align-items-center">
            <div className="" >
              <ThemeProvider theme={theme}>
                <Button variant="text" style={{color: determineColor('day'),}} onClick={() => {dispatch(setPeriod( SET_PERIOD , 'day')); updateDashboard('day');}} className="fd-control mx-2">Today</Button>
                <Button variant="text" style={{color: determineColor('week'),}} onClick={() => {dispatch(setPeriod( SET_PERIOD , 'week')); updateDashboard('week')}} className="fd-control mx-2">Week</Button>
                <Button variant="text" style={{color: determineColor('month'),}} onClick={() => {dispatch(setPeriod( SET_PERIOD , 'month')); updateDashboard('month')}} className="fd-control mx-2">Month</Button>
                <Button variant="text" style={{color: determineColor('year'),}} onClick={() => {dispatch(setPeriod( SET_PERIOD , 'year')); updateDashboard('year')}} className="fd-control mx-2">Year</Button>
              </ThemeProvider>
            </div>
            <div className="d-flex align-items-center">
                <Box mr={2} sx={{height: '50%'}}>
                <InputLabel shrink={'true'} size={'small'} mt={2}>Start Date</InputLabel>
                <Input
                size={'small'}
                id="start-date"
                type="date"
                label="Start Date"
                value={startDate}
                inputProps={{}}
                onChange={(e) => setCustomDateRange(e.target.value, 'start')}
                onBlur={() => updateDashboard(financeDashboardPeriod)}
              />
              </Box>
              <Box>
                <InputLabel shrink={'true'} size={'small'}>End Date</InputLabel>
                <Input
                size={'small'}
                id="end-date"
                type="date"
                label="End Date"
                value={endDate}
                onChange={(e) => setCustomDateRange(e.target.value, 'end')}
                onBlur={() => updateDashboard(financeDashboardPeriod)}
                inputProps={{ min: startDate}}
              />
              </Box>
            </div>
            <div className="d-flex align-items-center">
              <FormControl variant="standard" sx={{ minWidth: 120 }} className="mx-2">
                  <InputLabel id="demo-simple-select-standard-label">Region</InputLabel>
                  <Select
                      value={(regionValue ?? [])}
                      onChange={(event) => onRegionChange(event)}
                      sx={{ width: 300 }}
                      disabled={role !== 'master'}
                    >
                      {regionOptions.map(option => {
                        return (
                          <MenuItem key={option.code} value={option.code}>
                            {option.description}
                          </MenuItem>
                        )
                      })}
                  </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: 120 }} className="mx-2">
              <InputLabel id="demo-simple-select-standard-label">Location { role !== 'master' && me?.additionalLocationCodes?.length > 0 ? "("+formatLocationAccessString(me?.additionalLocationCodes)+")" : ""}</InputLabel>
                  <Select
                      value={(locationValue ?? [])}
                      onChange={(event) => onLocationChange(event)}
                      sx={{ width: 300 }}
                    >
                      {locationOptions?.map(option => {
                        return (
                          <MenuItem key={option.code} value={option.code}>
                            {option.description}
                          </MenuItem>
                        )
                      })}

                      { role !== 'master' && tempLocationOptions?.length > 0 ? (
                          <hr></hr>
                      ):null}
                      { role !== 'master' && tempLocationOptions?.map(option => {
                        return (
                          <MenuItem key={option.code} value={option.code}>
                            {option.description}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>

                  <IconButton   
                    disabled={true}
                    data-bs-toggle="modal"
                    data-bs-target="#financeDashboardSettingsModal"
                    component="span">
                    <Settings />
                  </IconButton>
            </div>
        </div>

    </div>
  );
}


export default FinanceBookedHeader;
