import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Navbar from "../../../Components/Navbar/Navbar";
import { useFetch } from "../../../utlis/api";
import moment from 'moment';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box"
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import allLocations from "../../../redux/allLocations";
import * as api from '../../../utlis/api'
import { setAllLocations } from "../../../redux/allLocations";




const UserAccess = () => {

  const dispatch              = useDispatch();
  const allLocations = useSelector((state) => state?.allLocations);


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const axios     = useFetch()
  const [usersLoaded, setUsersLoaded]=useState(false);
  const [users, setUsers]=useState([]);
  const [isLoading, setIsLoading]=useState(false);
  const [locationAccessGrants, setLocationAccessGrants] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState('Add');
  const [modalUserLocationAccessGrant, setModalUserLocationAccessGrant] = useState({});
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState(undefined);

  const handleClose = () => {
    setModalUserLocationAccessGrant({});
    setSelectedUser(undefined);
    setSelectedLocations([]);
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    setIsOpen(false);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '5px',
    // p: 4,
  };

  useEffect(() => {

    async function loadLocations(){
        api.getMyLocations().then( locations => {
          if(locations?.length > 0){
            dispatch(setAllLocations(locations));
          }
        })
    }


    if (!allLocations || allLocations.length === 0) {
      loadLocations();
    }

  }, [ allLocations, dispatch ])

  useEffect(() => {


    if(usersLoaded === false && users && users.length === 0){
      setUsersLoaded(true);
      axios.get(`/user`).then((res) => {
        setUsers(res?.data);
        setUsersLoaded(false);
      })
    }

    if(isLoading === false && locationAccessGrants && locationAccessGrants.length === 0){
      // setIsLoading(true);
      // axios.get(`/user`).then((res) => {
      //   setUsers(res?.data);
      //   setIsLoading(false);
      // })
      
      axios.get(`/LocationAccessGrant`).then((res) => {
        setLocationAccessGrants(res?.data);
        setIsLoading(false);
      })
    }
  },[isLoading, setIsLoading, users, setUsers, axios, locationAccessGrants, usersLoaded])

  const findUser = (userId) => {
    let foundUser = users.find(user => user.id === userId);
    if(foundUser){
      return foundUser;
    }
    return {};
  }

  const addUserLocationAccessGrant = () => {
    setModalUserLocationAccessGrant({});
    setSelectedUser(undefined);
    setSelectedLocations([]);
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    setModalMode('Add');
    setIsOpen(true);
  }

  const saveUserLocationAccessGrant = () => {
    let saveObject = modalUserLocationAccessGrant;
    saveObject.userId = selectedUser.id;
    saveObject.accessStartDate = selectedStartDate;
    saveObject.accessEndDate = selectedEndDate;

    let formattedLocationCodes = "";
    if(selectedLocations && selectedLocations.length > 0){
      formattedLocationCodes = selectedLocations.map(loc => {
        return loc.locationCode;
      });
      formattedLocationCodes = formattedLocationCodes.join(',');
      ;
      saveObject.locationCodes = formattedLocationCodes;
    }

    if(saveObject.id){
      // PUT
      
      api.updateUserAccessLocationGrant(saveObject).then(res => {
        setLocationAccessGrants([])

      });



    }else{
      // POST

      api.saveUserAccessLocationGrant(saveObject).then(res => {
        setLocationAccessGrants([])
      });

    }
  }

  const editUserLocationAccessGrant = (id) => {
    setModalUserLocationAccessGrant({});
    setSelectedUser(undefined);
    setSelectedLocations([]);
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);

    var foundGrant = locationAccessGrants.find(g => g.id === id);
    if(foundGrant){
      setModalUserLocationAccessGrant(foundGrant);

      if(foundGrant.userId && foundGrant.userId > 0){
        let user = findUser(foundGrant.userId);
        if(user){
          setSelectedUser(user);
        }
      }

      if(foundGrant.accessStartDate){
        setSelectedStartDate(moment.utc(foundGrant.accessStartDate).format('YYYY-MM-DD'));
      }

      if(foundGrant.accessEndDate){
        setSelectedEndDate(moment.utc(foundGrant.accessEndDate).format('YYYY-MM-DD'));
      }

      if(foundGrant.locationCodes){
        let grantLocations = foundGrant.locationCodes.split(',');
        let locations = [];
        grantLocations.forEach((code) => {
          let foundLocation = allLocations.find(loc => loc.locationCode === code);
          if(foundLocation){
            locations.push(foundLocation);
          }
        });
        setSelectedLocations(locations);
      }

      setModalMode('Edit');
      setIsOpen(true);
    }else{
      // Display Error
    }

  }

  // const deleteUserLocationAccessGrant = (id) => {
  //   api.deleteUserAccessLocationGrant(id).then(res => {
  //     setLocationAccessGrants([])
  //   });
  //   // setIsOpen(true);
  // }

  return (
    <>
    <Navbar title="User Location Access" />
      <div className="mx-4 mt-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h3>User Location Access</h3>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button onClick={addUserLocationAccessGrant} className="btn btn-success ma-4 pa-4">Add User Location Access Grant</button>
          </div>
        </div>
      </div>
      <br />

      {/* <Card> */}
        <CardContent style={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="left">User</StyledTableCell>
                <StyledTableCell align="left">Locations Granted</StyledTableCell>
                <StyledTableCell align="left">Start Date</StyledTableCell>
                <StyledTableCell align="left">End Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locationAccessGrants && locationAccessGrants.length > 0 ? ( locationAccessGrants.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    <button className="btn btn-default" onClick={() => {editUserLocationAccessGrant(row.id);}}>Edit</button>
                    {/* <button className="btn btn-default btn-text" style={{ color: 'red'}} onClick={() => {deleteUserLocationAccessGrant(row.id);}}>Delete</button> */}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {( users && users.length > 0 ? findUser(row.userId)?.name : "")}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.locationCodes}</StyledTableCell>
                  <StyledTableCell align="left">{moment.utc(row.accessStartDate).calendar()}</StyledTableCell>
                  <StyledTableCell align="left">{moment.utc(row.accessEndDate).calendar()}</StyledTableCell>
                </StyledTableRow>
              ))):null}
            </TableBody>
          </Table>
        </TableContainer>
        </CardContent>
      {/* </Card> */}
      <Modal 
        id={`add-edit-user-location-access-grant`}
        open={isOpen}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div className="modal-header bg-primary">
            <h4 className="modal-title text-white mt-2">
              { modalMode } User Location Access Grant
            </h4>
            <button
              type="button"
              className="btn-close bg-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={e => handleClose()}
            ></button>
          </div>
          <div style={{ padding: '1.2rem'}}>
            <Autocomplete
              disabled={(modalMode === "Edit" ? true : false)}
              inputValue={selectedUser?.name}
              value={selectedUser?.name}
              renderInput={(params) => <TextField {...params} label="User..." />}
              onChange={(e, newValue) => {
                setSelectedUser(newValue);
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              options={users}
              getOptionLabel= {(option) => option.name}
              />
              <br />
            <Autocomplete
              multiple
              // inputValue={selectedLocations ?? null}
              value={selectedLocations ?? null}
              renderInput={(params) => <TextField {...params} label="Locations" />}
              options={allLocations ?? []}
              filterSelectedOptions
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.locationID}>
                    {option.description}
                  </li>
                );
              }}
              getOptionLabel={(option) => option.locationCode.toString()}
              onChange={(e, newValue) => {
                setSelectedLocations(newValue);
              }}
              />

            <div>
              <label htmlFor="stock">Start Date</label>
              <input
                disabled={(modalMode === "Edit" ? true : false)}
                type="date"
                className="form-control rounded-pill mt-1"
                name="accessStartDate"
                onChange={(e, newValue) => {
                  let { value } = e.target;
                  setSelectedStartDate(value);
                }}
                value={selectedStartDate ?? null}
                min={ moment.utc().format("YYYY-MM-DD") }
                />
            </div>

            <div>
              <label htmlFor="stock">End Date</label>
              <input
                type="date"
                className="form-control rounded-pill mt-1"
                name="accessEndDate"
                onChange={(e, newValue) => {
                  let { value } = e.target;
                  setSelectedEndDate(value);
                }}
                value={selectedEndDate ?? null}
                />
            </div>
            <br />
            <div className="mx-auto text-center">
              <button className="btn btn-primary" onClick={() => { 
                  saveUserLocationAccessGrant();
               }}>Save</button>
            </div>
          </div>
        </Box>  
      </Modal>
    </>
  );
}

export default UserAccess;
